body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbarbg{
  background-color: #0c4379;
}

.remove-line{
  text-decoration: none;
  color: black;
}

.success{
  color: green;
  text-align: center;
  /* font-weight: bold; */
}

.failure{
  color: red;
  text-align: center;
  /* font-weight: bold; */
}

.menu:hover{
  cursor: pointer;
}

.bg1{
  background-color: #a9dce3;
  color: #153be7;
}

.bg2{
 background-color: #efc8b1;
 color: #1f1e1e;
}

.bg3{
 background-color: #e6bcf4;
 color: #5e057e;
}

.bg4{
 background-color: #ffb8b1;
 color: #b90e25;
}

.bg5{
 background-color: #f9858b;
 color: #761137;
}

.grow{
 transition: all .2s ease-in-out;
}

.grow:hover{
 transform: scale(1.1);
}

.textjustify{
  text-align: justify;
}

.removehover{
  text-decoration: none;
}

.lihover{
  padding: 10px;
}

.lihover:hover{
  cursor: pointer;
  background-color: rgb(232, 40, 6);
}

.ulwidth{
  width: 240px;
}

.ulwidth1{
  width: 150px;
}

.ulwidth2{
  width: 170px;
}

.icon-border{
  width: 45px;
  border: 10px solid white;
  border-radius: 50%;
  background-color: white;
}

.icon-border1{
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.table-lineheight{
  line-height: 5px;
}

.textbox-width{
  width: 115px !important;
}

.div-display{
  display:block;
}

.div-nodisplay{
  display: none;
}

.purple{
  background-color: purple;
  transition:background-color 1s;
}

.purple:hover{
  background-color:#0c4379;
  cursor: pointer;
}

.purple:focus{
  background-color:#92082d;
  cursor: pointer;
}